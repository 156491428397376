@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply text-gray-800 text-base;
}
input[type="text"],input[type="password"],input[type="email"],input[type="date"],select {
    @apply border rounded-md text-base;
}

button.primary {
    @apply bg-primary-800 p-2 w-full text-white rounded-md my-1 py-2 px-3 text-base;
}